import React, { useState, useEffect, useMemo } from 'react';
import * as S from './CookieContainer.styles';
import { Builder } from '@builder.io/react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const CookieContainer = props => {
  const { children, attributes, checkUrlParams } = props;
  const cookiesList = useMemo(() => props.cookies || [], [props]);

  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!checked) {
      let queryParams;
      if (checkUrlParams) {
        queryParams = new URLSearchParams(window.location.search);
      }
      cookiesList.map(({ cookieKey, cookieValue, contains }) => {
        if (checkUrlParams) {
          if (queryParams.get(cookieKey)) {
            cookies.set(cookieKey, queryParams.get(cookieKey) ?? '');
          }
        }
        if (Object.keys(cookies.getAll()).includes(cookieKey)) {
          if (cookies.get(cookieKey) === cookieValue) {
            setShow(true);
          }
          if (contains && cookies.get(cookieKey).includes(cookieValue)) {
            setShow(true);
          }
        } else {
          if (`` === cookieValue) {
            setShow(true);
          }
        }
        return null;
      });
    }
    return () => {
      setChecked(true);
    };
  }, [setShow, cookiesList, checked, setChecked, checkUrlParams]);

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  const Container = () => {
    return (
      <S.Container
        {...attributes}
        layerId="MULTIPLE COOKIES"
        editing={Builder.isEditing}
      >
        {children || noChildren()}
      </S.Container>
    );
  };

  if (show || Builder.isEditing) {
    return <Container />;
  } else {
    return ``;
  }
};

export default CookieContainer;
